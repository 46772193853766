
<template>
  <div id="app">
    <button
      @click="aberlourExport()"
      class="
        px-3
        py-2
        mb-3
        bg-green-500
        text-white
        font-semibold
        hover:bg-green-600
      "
    >
      <i class="fa fa-download mr-2"></i>Export
    </button>

    <ag-grid-vue
      style="width: 100%; height: 1000px"
      class="ag-theme-alpine text-xs"
      :column-defs="columnDefs"
      @grid-ready="onGridReady"
      @filter-changed="updateDashboard"
      :pagination="true"
      :row-data="rowData"
      rowSelection="multiple"
      :grid-options="gridOptions"
      :framework-components="frameworkComponents"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
// import "ag-grid-enterprise";// set ag grid license

var enterprise = require('../../../node_modules/ag-grid-enterprise')
enterprise.LicenseManager.setLicenseKey('CompanyName=Codable,LicensedGroup=codable-auriga,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-012971,ExpiryDate=15_January_2022_[v2]_MTY0MjIwNDgwMDAwMA==af77c39266e5878d29de38272117915c')

import GiftAidEligibleField from "./FieldRenderers/GiftAidEligibleField.js";
import DonorField from "./FieldRenderers/DonorField.js";
import CampaignField from "./FieldRenderers/CampaignField.js";
import MailLinkField from "./FieldRenderers/MailLinkField.js";

export default {
  name: "main-donations-grid",
  components: {
    AgGridVue,
  },
  data() {
    return {
      defaultColDef: null,
      columnDefs: [
        {
          headerName: "Ref #",
          field: "id",
          width: 100,
          filter: "agTextColumnFilter",
          sortable: true,
        },
        {
          headerName: "Donor Name",
          field: "donor.name",
          cellRenderer: "DonorField",
          width: 180,
          filter: "agTextColumnFilter",
          sortable: true,
          floatingFilter: true,
        },
        // {
        //   headerName: 'Donor Email',
        //   field: 'donor.email'
        // },
        {
          headerName: "Type",
          field: "donation_type.name",
          width: 140,
          filter: "agTextColumnFilter",
          sortable: true,
          floatingFilter: true,
        },
        {
          headerName: "Amount",
          field: "amount",
          valueFormatter: (params) =>
            currencyFormatter(params.data.amount, "GBP"),
          width: 120,
          sortable: true,
        },
        {
          headerName: "GA",
          field: "gift_aid_eligible",
          width: 80,
          cellRenderer: "GiftAidEligibleField",
        },
        // {
        //   headerName: 'GA Amount',
        //   field: 'gift_aid_amount',
        //   valueFormatter: (params) =>
        //          currencyFormatter(params.data.gift_aid_amount, 'GBP'),
        //   width: 130
        // },
        {
          headerName: "Date",
          field: "donation_date_formatted",
          filter: "agDateColumnFilter",
          filterParams: filterParams,
          width: 280,
          sortable: true,
          floatingFilter: true,
        },
        {
          headerName: "Campaign",
          field: "campaign.name",
          cellRenderer: "CampaignField",
          width: 190,
          filter: "agTextColumnFilter",
          sortable: true,
          floatingFilter: true,
        },
        {
          headerName: "",
          field: "id",
          cellRenderer: "MailLinkField",
          width: 190,
          sortable: false,
        },
      ],
      rowData: [],
      detailCellRendererParams: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      frameworkComponents: null,
    };
  },
  beforeMount() {
    this.gridOptions = {};

    this.getData();

    this.frameworkComponents = {
      GiftAidEligibleField: GiftAidEligibleField,
      DonorField: DonorField,
      CampaignField: CampaignField,
      MailLinkField: MailLinkField,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.columnApi = this.gridOptions.columnApi;
  },
  methods: {
    onGridReady() {
      // this.autoSizeAll();
    },
    getData() {
      axios.post("/get-donations").then((res) => {
        this.rowData = res.data.donations;
      });
    },
    autoSizeAll(skipHeader) {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });

      this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    aberlourExport() {
      const FileDownload = require("js-file-download");

      const dateFilter =   this.gridApi.getFilterInstance("donation_date_formatted").appliedModel;
      const typeFilter =   this.gridApi.getFilterInstance("donation_type.name").appliedModel;
      const nameFilter =   this.gridApi.getFilterInstance("donor.name").appliedModel;
      const campaignFilter =  this.gridApi.getFilterInstance("campaign.name").appliedModel;
    

          axios({
            url: "/export-data",
            params: { nameFilter: nameFilter, typeFilter: typeFilter, dateFilter:dateFilter, campaignFilter: campaignFilter },
            method: "GET",
            responseType: "blob",
          }).then((response) => {
            FileDownload(response.data, "donationsExport.xlsx");
          });
        
    },
    updateDashboard() {
      this.gridApi.getFilterInstance(
        "donation_date_formatted",
        (filterInstance) => {
          const appliedModel = filterInstance.appliedModel;

          this.$root.$emit("filterApplied", appliedModel);
        }
      );
    },
  },
};

var filterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue.substr(0, cellValue.indexOf(" "));

    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  },
  browserDatePicker: true,
  buttons: ["clear"],
  defaultOption: "inRange",
  suppressAndOrCondition: true,
  inRangeInclusive: true,
};

//currency formatter for AG-Grid
function currencyFormatter(currency, code) {
  var sign = code == "GBP" ? "£" : "€";

  if (currency) {
    var sansDec = parseFloat(currency).toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    var formatted = 0.0;
  }
  return sign + `${formatted}`;
}
</script>

