import axios from 'axios';
import Vue from 'vue'

// import { EventBus } from '../Infrastructure/Event-bus.js'

export default Vue.extend({
  data: function () {
    return {
        donation:""
    }
  },
  beforeMount () {},
  mounted () {

    this.donation = this.params.data;

  },
  methods:{
    //   openModal(){
    //     this.$modal.show("view-application-modal", {application: this.params});
    // },
    // revertFromPaid(){

    //     var applicationId = this.params.data.id;

    //     axios.post('/urgent-funding-applications/'+applicationId+'/change-status', {status_id: 7 })
    //     .then(data=> EventBus.$emit('modalUpdate'));

    // },
    // allowedToDelete(){

    //     return this.params.data.allowed_to_delete;

    // },
    // deleteApplication(){

    //     var applicationId = this.params.data.id;


    //     this.$swal({
    //         title: 'Do you want to delete the application #'+ applicationId +'?',
    //         showDenyButton: true,
    //         showCancelButton: false,
    //         confirmButtonText: `Delete`,
    //         denyButtonText: `Cancel`,
    //       }).then((result) => {
    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {

    //             axios.post('/urgent-funding-applications/'+applicationId+'/delete').then(
    //                 data => {
    //                     this.$swal('Deleted!', '', 'success');
    //                     EventBus.$emit('modalUpdate');
    //                 }
    //             )

    //         } else if (result.isDenied) {
    //            this.$swal('Application not deleted!', '', 'info');
    //         }
    //       })

    // }

  },
  template: `
  <span v-if="donation && donation.donor">
        <a target="_blank" :href="'/donor-notification/'+donation.id+'/'+donation.donor.id" class="text-aberlour-blue hover:underline cursor-pointer mr-2"><i class="fas fa-envelope-open-text"></i></a>

        <a target="_blank" :href="'/aberlour-notification/'+donation.id+'/'+donation.donor.id" class="text-aberlour-green hover:underline cursor-pointer"><i class="fas fa-info-circle"></i></a>

  </span>

`
});
