<template>
  <modal
    name="donor-modal"
    width="60%"
    height="auto"
    :draggable="false"
    :click-to-close="true"
    transition="scale"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="text-gray-700" v-if="donor">

    <div  class="flex bg-white w-full justify-between px-5 py-3">
      <h3>
       <i class="fas fa-user mr-2"></i> Donor Information - <span class="font-bold">{{donor.name}}</span>
      </h3>
      <span style="cursor:pointer;" class="pull-right" @click="$modal.hide('donor-modal')">
        <i class="fa fa-times font-default"></i>
      </span>
    </div>
    <hr />
    <div class=" w-full px-5 py-3">

        <div class="grid grid-cols-3 mb-5">
            <!-- contact details -->
            <div class="flex-col border-r border-gray-400 mr-3">
                <span class="font-bold text-lg underline"><i class="far fa-address-card text-blue-900 mr-2"></i>Contact Details</span>
                <div><a class="text-blue-500 hover:underline italic" :href="'mailto:'+donor.email"> {{ donor.email }}</a></div>
                <div> {{ donor.phone }}</div>
                <div class="flex-col pt-3">
                    <div>{{ donor.house_number }} {{donor.address_line_1}}</div>
                    <div>{{ donor.address_line_2 }}</div>
                    <div>{{ donor.address_town }}</div>
                    <div>{{ donor.address_region }}</div>
                    <div>{{ donor.address_postcode }}</div>
                </div>
            </div>

            <!-- donation details -->
            <div class="flex-col border-r border-gray-400 pr-3">
                <span class="font-bold text-lg underline"><i class="fad fa-money-bill-wave text-green-500 mr-2"></i>Donations</span>
                <div class="my-3 border-b border-gray-200 pb-3"><span class="my-5 font-semibold">Total Donated:</span> <span class="px-2 py-1 font-semibold rounded bg-green-500 text-white float-right"> £{{ donor.donation_total.toFixed(2) }}</span></div>
                <div class="my-3 border-b border-gray-200 pb-3"><span class="font-semibold">Donation Count:</span> <span class="px-2 py-1 font-semibold rounded bg-blue-500 text-white float-right"> {{ donor.donation_count }}</span></div>
                <div><span class="font-semibold"><i class="fal fa-calendar-alt mr-2"></i>First Donation:</span> <span class="float-right">{{ donor.first_donation }}</span></div>
                <div><span class="font-semibold"><i class="fal fa-calendar-alt mr-2"></i>Last Donation:</span> <span class="float-right">{{ donor.last_donation }}</span></div>
            </div>

            <!-- Additional Info -->
            <div class="flex-col pl-3">
                <span class="font-bold text-lg underline"><i class="far fa-info-circle mr-2 text-blue-500"></i>Additional</span>
                <div class="pt-3"><span class="font-semibold">UK Taxpayer:</span><i class="fad fa-2x float-right" :class="donor.uk_taxpayer == 1 ? 'fa-check-square text-green-500' : 'fa-times-square text-red-500'"></i></div>
                <div class="pt-3 pb-1 text-sm text-gray-400">Marketing Preferences:</div>
                <div class=""><span class="font-semibold">Email:</span><i class="fad fa-2x float-right" :class="donor.marketing_pref_email == 1 ? 'fa-check-square text-green-500' : 'fa-times-square text-red-500'"></i></div>
                <div class="pt-3"><span class="font-semibold">Phone:</span><i class="fad fa-2x float-right" :class="donor.marketing_pref_phone == 1 ? 'fa-check-square text-green-500' : 'fa-times-square text-red-500'"></i></div>
                <div class="pt-3"><span class="font-semibold">Post:</span><i class="fad fa-2x float-right" :class="donor.marketing_pref_post == 1 ? 'fa-check-square text-green-500' : 'fa-times-square text-red-500'"></i></div>
                <div class="pt-3"><span class="font-semibold">Text:</span><i class="fad fa-2x float-right" :class="donor.marketing_pref_text == 1 ? 'fa-check-square text-green-500' : 'fa-times-square text-red-500'"></i></div>
            </div>

        </div>

    </div>
    <div v-if="donor.feedback" class="w-full px-5 py-3">
        <span class="font-bold text-xl">Feedback Left by Donor:</span>
        <div class = "grid grid-cols-3 font-semibold gap-5">
            <div>(Id) Donation Reason</div>
            <div>Feedback</div>
            <div>Date</div>
        </div>


        <div class = "grid grid-cols-3 gap-5" v-for="feedback in donor.feedback" :v-key="donor.feedback.id">
            <div>({{feedback.donation_id}}) {{feedback.donation_reason == "Please choose a reason ..." ? 'None Selected' : feedback.donation_reason}}</div>
            <div>{{ feedback.donation_feedback}}</div>
            <div>{{feedback.feedback_date_formatted}}</div>
        </div>



    </div>







    </div>
  </modal>
</template>

<script>

// import { EventBus } from "../Infrastructure/Event-bus.js"

export default {
  components: {
  },
  data() {
    return {
        donor:null,
    };
  },
  computed: {},
  methods: {
    beforeOpen(event) {
     this.donor = event.params.donor.data.donor;
    //  console.log(this.donor);

    },
    beforeClose(event) {
        this.donor = null;
    },
    sendInfoRequest(){

        //todo

    }

  }
};
</script>

<style></style>
