/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue').default;




window._ = require('lodash');


import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: 'c50bc554c28cc655e33602f513c93d2e',
  plugins: [new BugsnagPluginVue()]
})

const bugsnagVue = Bugsnag.getPlugin('vue')
// bugsnagVue.installVueErrorHandler(Vue)


window.axios = require('axios');

import { store } from './store'

import debounce from 'lodash/debounce';

//vue select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import 'vue-transitions-css';




/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('donation-type-selector', require('./components/Donation-type-selector.vue').default);
Vue.component('stripe', require('./components/Stripe.vue').default);
Vue.component('main-donation-form', require('./components/Main-donation-form.vue').default);

Vue.component('main-nav-bar', require('./components/Main-nav-bar.vue').default);
Vue.component('donation-stage-indicator', require('./components/Donation-stage-indicator.vue').default);

Vue.component('main-donations-grid', require('./components/Main-donations-grid.vue').default);
Vue.component('main-people-grid', require('./components/Main-people-grid.vue').default);

Vue.component('main-campaign-grid', require('./components/Main-campaign-grid.vue').default);
Vue.component('add-campaign-button', require('./components/Add-campaign-button.vue').default);


Vue.component('dashboard-statistics', require('./components/Dashboard-statistics.vue').default);

Vue.component('donor-modal', require('./components/Modals/Donor-modal.vue').default);
Vue.component('add-campaign-modal', require('./components/Modals/Add-campaign-modal.vue').default);
Vue.component('card-authenticate-modal', require('./components/Modals/Card-authenticate-modal.vue').default);

Vue.component('thank-you-form', require('./components/Thank-you-form.vue').default);




import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);

import vmodal from 'vue-js-modal'
Vue.use(vmodal)

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LfPq-geAAAAAKDfUezpZAJIRtZjxo0CAmsWGCf_' })

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)







/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    store,
    el: '#app',
});
