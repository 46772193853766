<template>
  <div>
    <div
      class="
        w-full
        relative
        py-3
        px-1
        md:px-4
        cursor-pointer
        text-center
        font-semibold
        text-sm
        md:text-md
        rounded-md
      "
      :class="
        localSelected
          ? 'bg-aberlour-purple text-white donation-type-selector'
          : 'text-aberlour-purple border-purple-700 border donation-type-selector-unselected  hover:bg-gray-300  hover:text-white '
      "
      @click="handleSelected()"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    selected: {
      default: false,
    },
    selectedTitle:null
  },
  data() {
    return {
      localSelected: this.selected,
    };
  },
  mounted() {
    this.$root.$on("unselect-donation-type-selection", (data) => {
      this.localSelected = false;
    });

    if(this.selectedTitle == this.title){
        this.localSelected = true;
    }

  },
  methods: {
    handleSelected() {
      this.$root.$emit("unselect-donation-type-selection", {
        selected: this.title,
      });
      this.localSelected = !this.localSelected;
    },
  },
};
</script>

<style>
/*styles to add triangles below buttons */

/* .donation-type-selector:after {
        position: absolute;
        top: 98.1%;
        left: calc(50% - 25px);
        content: '';
        width: 0;
        height: 0;
        border-top: solid 25px #4C1D95;
        border-left: solid 25px transparent;
        border-right: solid 25px transparent;

    }

       .donation-type-selector:hover:after {
         border-top-color:#4C1D95;
       }

        .donation-type-selector-unselected:after {
        position: absolute;
        top: 98.1%;
        left: calc(50% - 25px);
        content: '';
        width: 0;
        height: 0;
        border-top: solid 25px #D1D5DB;
        border-left: solid 25px transparent;
        border-right: solid 25px transparent;


    }

    .donation-type-selector-unselected:not(:focus):hover:after {
        border-top-color:#C4B5FD;
    }
 */
</style>
