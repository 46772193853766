import axios from 'axios';
import Vue from 'vue'

// import { EventBus } from '../Infrastructure/Event-bus.js'

export default Vue.extend({
  data: function () {
    return {
        donor:false
    }
  },
  beforeMount () {},
  mounted () {

    // console.log(this.params)

    this.donor = this.params.data;

  },
  methods:{
      openModal(){
        this.$modal.show("donor-modal", {donor:{data:{donor:this.params.data}} });
    },

  },
  template: `
  <span>
        <span @click="openModal()" class="text-blue-500 hover:underline cursor-pointer">{{donor.last_name}}</span>
  </span>

`
});
