<template>
  <div class="pt-0 md:pt-4">
    <div class="text-black font-semibold text-xl">
      <div class="w-full flex">
        <i
          @click="back()"
          v-if="currentlyActiveStage.number > 1"
          class="far fa-arrow-circle-left float-left cursor-pointer"
        ></i>

        <div class="mx-auto flex">
          <div
            v-for="stage in stages"
            :key="stage.number"
            class="text-base text-center"
          >
            <i :class="stageIcon(stage)"></i
            ><i
              v-if="stage.number != 5"
              class="fas fa-horizontal-rule font-thin"
              :class="stage.status == 'complete' ? 'text-green-500' : ''"
            ></i>
          </div>
        </div>
        <i
          @click="next()"
          v-if="
            currentlyActiveStage.number > 1 &&
            this.nextStage.status == 'complete'
          "
          class="far fa-arrow-circle-right float-right cursor-pointer"
        ></i>
      </div>
      <div class="w-full text-center mt-4 text-sm sm:text-base mb-3">
        {{ currentlyActiveStage.number }}. {{ currentlyActiveStage.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentlyActiveStage() {
      return this.$store.getters.currentlyActiveStage;
    },
    stages() {
      return this.$store.getters.stages;
    },
    previousStage() {
      return this.$store.getters.prevStage;
    },
    nextStage() {
      var nextStage = this.$store.getters.nextStage;

      if (!nextStage) {
        nextStage = { status: "todo" };
      }

      return nextStage;
    },
  },
  methods: {
    stageIcon(stage) {
      let iconClass = "far fa-circle font-thin text-gray-800";

      if (stage.status == "active") {
        iconClass = "fad fa-circle border-gray-500 font-thin rounded-full";
      }

      if (stage.status == "complete") {
        iconClass = "fas fa-check-circle text-green-500 bg-white";
      }

      return iconClass;
    },
    stageLink(stage) {
      this.$store.dispatch("goToClickedStage", stage);
    },
    back(stage) {
      this.$root.$emit("clear-payment-request");
      this.$store.dispatch("goToClickedStage", this.previousStage);
    },
    next(stage) {
      this.$store.dispatch("goToClickedStage", this.nextStage);
    },
  },
};
</script>

<style scoped>
/* style to make the FA backgrounds white if not on a white background */
.fa-bg-white {
  position: relative;
}

.fa-bg-white:before {
  position: relative;
  z-index: 1;
}

.fa-bg-white:after {
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  z-index: 0;
  top: 10%;
  left: 10%;
  width: 75%;
  height: 75%;
}
</style>
