
<template>
  <div id="app">
    <ag-grid-vue
      style="width: 100%; height: 1000px"
      class="ag-theme-alpine text-xs"
      :column-defs="columnDefs"
      @grid-ready="onGridReady"
      :pagination="true"
      :row-data="rowData"
      rowSelection = "multiple"
      :grid-options="gridOptions"
      :framework-components="frameworkComponents"



    >
    </ag-grid-vue>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue"
import 'ag-grid-enterprise';

import GiftAidEligibleField from "./FieldRenderers/GiftAidEligibleField.js";
import PeopleFirstNameDonorField from "./FieldRenderers/PeopleFirstNameDonorField.js";
import PeopleLastNameDonorField from "./FieldRenderers/PeopleLastNameDonorField.js";

import CampaignField from "./FieldRenderers/CampaignField.js";


export default {
  name: 'main-people-grid',
   components: {
        AgGridVue
    },
  data() {
    return {
      columnDefs: [
        {
          headerName: 'Ref #',
          field: 'id',
          width: 100
        },
        {
          headerName: 'First Name',
          field: 'first_name',
          width: 140,
          cellRenderer:'PeopleFirstNameDonorField',
          filter: 'agTextColumnFilter',
          sortable: true,
          floatingFilter: true
        },
        {
          headerName: 'Last Name',
          field: 'last_name',
          width: 140,
          cellRenderer:'PeopleLastNameDonorField',
          filter: 'agTextColumnFilter',
          sortable: true,
          floatingFilter: true

        },
        {
          headerName: 'Email',
          field: 'email',
          width: 220,
          filter: 'agTextColumnFilter',
          sortable: true,
          floatingFilter: true

        },
        {
          headerName: 'Last Donation',
          field: 'last_donation',
          width: 180,
          sortable:true
        },
        {
          headerName: 'Total',
          field: 'donation_total',
          valueFormatter: (params) =>
                 currencyFormatter(params.data.donation_total, 'GBP'),
          width: 130,
          sortable:true
        },
        {
          headerName: '#',
          field: 'donation_count',
          width: 80,
          sortable:true

        },
        {
          headerName: 'Location',
          field:'address_region',
          filter: 'agTextColumnFilter',
          sortable: true,
          floatingFilter: true
        }

      ],
      rowData: [],
      detailCellRendererParams: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      frameworkComponents: null
    }
  },
   beforeMount() {


    this.gridOptions = {}

    this.getData();

        this.frameworkComponents = {
        GiftAidEligibleField: GiftAidEligibleField,
        PeopleFirstNameDonorField: PeopleFirstNameDonorField,
        PeopleLastNameDonorField: PeopleLastNameDonorField,
        CampaignField: CampaignField
    }

  },
  mounted(){
  },
  methods: {
    onGridReady() {

        // this.autoSizeAll();
    },
    getData(){
        axios.post('/get-people').then(res => {
            this.rowData = res.data.people;
        })
    },
    autoSizeAll(skipHeader) {
        var allColumnIds = [];
        this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });

        this.gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

  }
}

//currency formatter for AG-Grid
function currencyFormatter(currency, code) {

  var sign = code == "GBP" ? '£' : '€'

  if(currency){

  var sansDec =  parseFloat(currency).toFixed(2);
  var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }else{
      var formatted = 0.00;
  }
  return sign + `${formatted}`;
}
</script>

