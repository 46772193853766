<template>
  <div>
    <div
      class="
        w-full
        h-19
        relative
        py-3
        px-1
        md:px-4
        cursor-pointer
        text-center
        font-semibold
        text-xs
        sm:text-sm
        rounded-md
      "
      :class="
        localSelected
          ? 'bg-aberlour-purple text-white donation-suggestion border-aberlour-purple border-2 '
          : 'text-aberlour-purple unselected hover:bg-gray-300  hover:text-white border-gray-700 border '
      "
      @click="handleSelected()"
    >
      <div
        v-if="suggestedAmount != 'Other'"
        :class="donationType == 'Single Donation' ? '' : ''"
      >
        £{{ suggestedAmount }}
      </div>
      <div v-else :class="donationType == 'Single Donation' ? '' : ''">
        <span class="text-xs sm:text-sm"> {{ suggestedAmount }}</span>
      </div>

      <div
        :class="localSelected ? ' text-white' : 'text-gray-700'"
        class="text-xs font-light text-gray-700 hidden sm:inline-block"
        v-if="donationType == 'Monthly Donation'"
      >
        a month
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suggestedAmount: {},
    selected: {
      default: false,
    },
    image: {},
    text: {},
    donationType: {},
    position: {},
  },
  data() {
    return {
      localSelected: this.selected,
    };
  },
  mounted() {
    this.$root.$on("unselect-donation-suggestions", (data) => {
      this.localSelected = false;
    });

    this.$root.$on("manual-donation-amount-entered", (data) => {
      if (data.amount == this.suggestedAmount) {
        this.$root.$emit("unselect-donation-suggestions", {
          suggestedAmount: this.suggestedAmount,
          image: this.image,
          text: this.text,
          position: this.position,
        });
        this.localSelected = true;
      }
    });
  },
  methods: {
    handleSelected() {
      this.$root.$emit("unselect-donation-suggestions", {
        suggestedAmount: this.suggestedAmount,
        image: this.image,
        text: this.text,
        position: this.position,
      });
      this.localSelected = !this.localSelected;
    },
  },
};
</script>

<style>
/* styles for triangles under buttons */

/* .donation-suggestion:after {
        position: absolute;
        top: 98.1%;
        left: calc(50% - 25px);
        content: '';
        width: 0;
        height: 0;
        border-top: solid 25px #4C1D95;
        border-left: solid 25px transparent;
        border-right: solid 25px transparent;

    }

       .donation-suggestion:hover:after {
         border-top-color:#4C1D95;
       }

        .unselected:after {
        position: absolute;
        top: 98.1%;
        left: calc(50% - 25px);
        content: '';
        width: 0;
        height: 0;
        border-top: solid 25px #ffffff;
        border-left: solid 25px transparent;
        border-right: solid 25px transparent;


    }

    .unselected:not(:focus):hover:after {
        border-top-color:#C4B5FD;
    } */
</style>
