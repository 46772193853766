<template>
  <modal
    name="add-campaign-modal"
    width="60%"
    height="auto"
    :draggable="false"
    :click-to-close="true"
    transition="scale"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="text-gray-700">

    <div  class="flex bg-white w-full justify-between px-5 py-3">
      <h3>
       <i class="far fa-bullseye-pointer mr-2"></i> Add Campaign</span>
      </h3>
      <span style="cursor:pointer;" class="pull-right" @click="$modal.hide('add-campaign-modal')">
        <i class="fa fa-times font-default"></i>
      </span>
    </div>
    <hr />
    <div class=" w-full px-5 py-3">

        <div class="grid grid-cols-3 mb-5">


        </div>

    </div>







    </div>
  </modal>
</template>

<script>

// import { EventBus } from "../Infrastructure/Event-bus.js"

export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    beforeOpen(event) {
    //  this.donor = event.params.donor.data.donor;
    //  console.log(this.donor);

    },
    beforeClose(event) {
    },
    sendInfoRequest(){

        //todo

    }

  }
};
</script>

<style></style>
