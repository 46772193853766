<template>

<div class=" sm:rounded-lg  w-full" :class="campaign == 1 || campaign == 3 || campaign == 4 || campaign == 5 || campaign == 6 ? 'mx-auto sm:w-1/2 bg-white shadow ': 'bg-white shadow mb-3 mt-3 '">



  <div v-if="!feedbackSubmitted" class="px-4 py-4 sm:p-6 mt-3">
    <h3 class="leading-6 font-medium text-xl text-center" :class="campaign == 1 || campaign == 3  || campaign == 4 || campaign == 5 || campaign == 6  ? 'text-gray-900' : 'text-gray-900'">
      Thanks for your donation!
    </h3>
    
    <div v-if="campaign == 6">
    <img class=" my-4 rounded-md" src="/img/poverty-relief-campaign-thank-you.webp" style="z-index:-10000;" />

    <p class="py-3">On behalf of the children and families you are supporting thank you very much. Your generosity means a great deal. It will help put food on the table and ensure that Emma and other children across the country have access to essentials like heating, lighting and a warm place to sleep.</p>
    </div>

    <div v-if="campaign == 1 || campaign == 3 || campaign == 4 || campaign == 5 || campaign == 6 " class="mt-2 max-w-xl text-xs text-center text-gray-500">
      <p>You will receive an email confirmation shortly</p>

    </div>
    <div v-if="campaign == 1 || campaign ==2 || campaign == 4 || campaign == 5 || campaign == 6 " class="mt-2 text-lg text-center" :class="campaign == 1 ? 'max-w-xl text-gray-900' : 'text-gray:900'">
      <p>What prompted you to donate today?</p>
    </div>

    <div v-if="campaign == 3" class="mt-2 text-base text-center" :class="campaign == 3 ? 'max-w-xl text-gray-900' : 'text-gray:900'">
      <p>As you donated to our Lent campaign, would you mind telling us some details of your <strong>church</strong> and <strong>where you are from?</strong></p>
    </div>

    <div class="mt-2 sm:items-center">
       <div class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5">
          <div v-if="campaign == 1 || campaign == 2 || campaign == 4 || campaign == 5 || campaign == 6 " class="mt-1 sm:mt-0" :class="campaign == 1 ? 'sm:col-span-2' :''">
            <select v-model="reason" id="reason" name="reason" autocomplete="off" class="w-full py-3 block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm  border-gray-300 rounded-md" placeholder="Please choose a reason...">
              <option>Please choose a reason ...</option>
              <option>Post sent to me</option>
              <option>Email sent to me</option>
              <option>I received a phone call</option>
              <option>TV Advert</option>
              <option>Radio Advert</option>
              <option>Other Advert - please share details</option>
              <option>Social media post</option>
              <option>Newspaper article</option>
              <option>I spoke with a Fundraiser</option>
              <option>Donation in memory of a loved one</option>
              <option>Donation from my business</option>
              <option>Donation from my church</option>
              <option>Donation from my community group</option>
              <option>Paying money I have collected/fundraised - please share details in the box below</option>
              <option>I or my family have been helped by Aberlour and want to give something back</option>
              <option>Personal connection to Aberlour</option>
              <option>Other - please let us know in the box below what prompted you to donate today</option>
            </select>
          </div>

          <div v-if="campaign == 3" class="sm:col-span-2">
            <input v-model="reason" type="text" name="feedback" placeholder="Please enter your Church name..." id="reason" autocomplete="off" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 text-gray-900 rounded-md py-3">
          </div>

          <div v-if="campaign == 3" class="sm:col-span-2">
            <input v-model="feedback" type="text" name="feedback" placeholder="In which town is your church located?" id="feedback" autocomplete="off" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 text-gray-900 rounded-md py-3">
          </div>

        </div>

        <div v-if="campaign == 1 || campaign == 2 || campaign == 4 || campaign == 5 || campaign == 6" class="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:pt-5 pt-4">
          <div class="mt-1 sm:mt-0 col-span-2">
            <input v-model="feedback" type="text" name="feedback" placeholder="+ Any feedback for us?" id="feedback" autocomplete="off" class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 text-gray-900 rounded-md py-3">
          </div>
        </div>
        <div class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5 pt-4">
          <div class="mt-1 sm:mt-0 sm:col-span-2">
              <button @click="submitFeedback()" class="bg-aberlour-green text-white w-full hover:bg-green-600 py-3 text-lg mb-3">Finish</button>
          </div>
        </div>
    </div>
  </div>
    <div v-else class="px-4 py-4 sm:p-6 mt-3">
    <h3 class="leading-6 font-medium text-xl text-center text-gray-900">
      Thanks for your feedback!
    </h3>

    <div class="mt-2 text-base text-gray-500 w-full">
      <p>Here’s a thank you message from one of our family support workers. Watch it to find out how your donations will help families trapped in poverty this winter.</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
    props:['donationId', 'donorId', 'campaign', 'donationValue'],
    data() {
        return {
            feedback:"",
            reason:"Please choose a reason ...",
            feedbackSubmitted:null,
        }
    },
    mounted(){
      if(this.campaign == 3){
        this.reason = ""
      }


      
          // gtag('event', 'purchase', {
          // 'allow_custom_scripts': true,
          // 'value': this.donationValue,
          // 'transaction_id': donationId,
          // 'send_to': 'DC-11763200/donat0/donat0+transactions'
          // });
    },
    methods: {
        submitFeedback(){

             this.$swal({
                    title: 'Please Wait',
                    icon: 'info',
                    text: 'Processing your feedback',
                    buttons: false,
                    timer: 4000,
                });

            axios.post('/feedback-submit',{donor_id: this.donorId, donation_id: this.donationId, reason: this.reason, feedback: this.feedback})
            .then((res) => {
                this.$swal({
                    title: 'Thank You',
                    icon: 'success',
                    text: 'We appreciate your feedback',
                    buttons: false,
                    timer: 4000,
                });
            });

            if(this.campaign == 1 || this.campaign == 4 || this.campaign == 5 || this.campaign == 6 ||  !this.campaign){

            setTimeout(() => {

                  window.top.location.href = "https://www.aberlour.org.uk";

            }, 3000);
            }else if(this.campaign == 2){

                this.feedbackSubmitted = true;
            }
        }
    },

}
</script>

<style>

</style>
