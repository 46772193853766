<template>
        <div :class="donationType == 'Monthly Donation' ? ' bubble-'+position : 'bubble-'+position" class="flex p-2 mb-3 items-center rounded-md mt-1">
            <!-- <img
            :src="image"
            /> -->
            <!-- <div class="text-center p-4">
            <p class="text-7xl text-purple-900 font-extrabold">
                £{{amount ? amount : 0}}
                <div class="text-sm font-thin" v-if="donationType == 'Monthly Donation'">per month</div>
            </p>
            </div> -->
            <div class="pl-1 pr-3">
                <i class="fas fa-info-circle text-aberlour-blue"></i>
            </div>
            <div class="">
            <p>
                {{text}}
            </p>
            </div>
        </div>
</template>

<script>
export default {
    props:{
            amount:{},
            image:{
                default:'/img/brand/donation-suggestions/4.jpg'
            },
            text:{
                default:'Your custom amount will help greatly by....Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim'
            },
            donationType:{},
            position: {
            }

          }

}
</script>

<style>


.bubble-1
{
position: relative;
padding: 0px;
background: #FFFFFF;
/* -webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px; */
border: #707070 solid 2px;
}

.bubble-1:after
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 12px 13px;
border-color: #FFFFFF transparent;
display: block;
width: 0;
z-index: 1;
margin-left: -12px;
top: -13px;
left: 11%;
}

.bubble-1:before
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 13px 14px;
border-color: #707070 transparent;
display: block;
width: 0;
z-index: 0;
margin-left: -13px;
top: -16px;
left: 11%;
}


.bubble-2
{
position: relative;
padding: 0px;
background: #FFFFFF;
/* -webkit-border-radius: 10px;
-moz-border-radius: 10px; */
/* border-radius: 10px; */
border: #707070 solid 2px;
}

.bubble-2:after
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 12px 13px;
border-color: #FFFFFF transparent;
display: block;
width: 0;
z-index: 1;
margin-left: -12px;
top: -13px;
left: 38%;
}

.bubble-2:before
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 13px 14px;
border-color: #9CA3AF transparent;
display: block;
width: 0;
z-index: 0;
margin-left: -13px;
top: -16px;
left: 38%;
}

.bubble-3
{
position: relative;
padding: 0px;
background: #FFFFFF;
/* -webkit-border-radius: 10px;
-moz-border-radius: 10px; */
/* border-radius: 10px; */
border: #9CA3AF solid 2px;
}

.bubble-3:after
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 12px 13px;
border-color: #FFFFFF transparent;
display: block;
width: 0;
z-index: 1;
margin-left: -12px;
top: -13px;
left: 64%;
}

.bubble-3:before
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 13px 14px;
border-color: #9CA3AF transparent;
display: block;
width: 0;
z-index: 0;
margin-left: -13px;
top: -16px;
left: 64%;
}


.bubble-4
{
position: relative;
padding: 0px;
background: #FFFFFF;
/* -webkit-border-radius: 10px;
-moz-border-radius: 10px; */
/* border-radius: 10px; */
border: #9CA3AF solid 2px;
}

.bubble-4:after
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 12px 13px;
border-color: #FFFFFF transparent;
display: block;
width: 0;
z-index: 1;
margin-left: -12px;
top: -13px;
left: 90%;
}

.bubble-4:before
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 13px 14px;
border-color: #9CA3AF transparent;
display: block;
width: 0;
z-index: 0;
margin-left: -13px;
top: -16px;
left: 90%;
}


</style>
