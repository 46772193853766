<template>

    <div @click="addCampaign()" class="float-right px-3 py-2 bg-green-500 cursor-pointer text-white font-semibold hover:bg-green-600"><i class="far fa-plus-square mr-2"></i>Add Campaign</div>

</template>

<script>
export default {

    data() {
    return {
        campaigns:null
    }
},
mounted() {
},

}

</script>

<style>

</style>
