<template>
   <!-- Dashboard Statistics -->
   <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
      <!-- Card -->
      <div
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-green-500 rounded-full dark:text-green-100 dark:bg-green-500"
            >
           <i class="fad fa-money-bill-wave fa-2x"></i>
         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
               Gross Donation
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
               £{{ grossDonation }}
            </p>
         </div>
      </div>
      <!-- Card -->
      <div
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-blue-500"
            >
                      <i class="fal fa-2x fa-tachometer-average"></i>

         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
                Avg Donation
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
               £{{ averageDonation }}
            </p>
         </div>
      </div>
      <!-- Card -->
      <div
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-gray-400"
            >
            <i class="fal fa-tally fa-2x"></i>
         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
               Donation Count
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
               {{ donationCount }}
            </p>
         </div>
      </div>
<br>
         <div
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-green-400"
            >
            <i class="fal fa-heart fa-2x"></i>
         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
               Campaign Gross
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
               £{{ currentCampaignTotal }}
            </p>
         </div>
      </div>

         <div
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-blue-400"
            >
            <i class="fal fa fa-bar-chart fa-2x"></i>
         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
                Campaign Avg
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
               £{{ currentCampaignAvg }}
            </p>
         </div>
      </div>

               <div
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-gray-400"
            >
            <i class="fal fa-tally fa-2x"></i>
         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
                Campaign Count
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
               {{ currentCampaignCount }}
            </p>
         </div>
      </div>
   </div>
</template>

<script>
export default {
    data(){
        return{
            dateParams:null,
            grossDonation: 0,
            averageDonation: 0,
            donationCount :0,
            currentCampaignCount: 0,
            currentCampaignTotal: 0,
            currentCampaignAvg: 0
        }
    },
    mounted(){

       this.$root.$on('filterApplied', (model) => {
           this.dateParams = model;
           this.getData();
       });

       this.getData();



    },
    methods: {
        getData(){
            this.getStatistics();
            // this.getDonationCount();
        },
        getStatistics(){
              axios({
                url: '/donations/statistics',
                params: {dates: this.dateParams},
                method: 'GET'})
            .then(result => {
                this.grossDonation = this.thousandsSeparators(result.data.grossDonation.toFixed(0));
                this.averageDonation = this.thousandsSeparators(result.data.averageDonation.toFixed(0));
                this.donationCount = this.thousandsSeparators(result.data.donationCount);
                this.currentCampaignCount = this.thousandsSeparators(result.data.currentCampaignCount);
                this.currentCampaignTotal = this.thousandsSeparators(result.data.currentCampaignTotal.toFixed(0));
                this.currentCampaignAvg = this.thousandsSeparators(result.data.currentCampaignAvg.toFixed(0));
            });
        },
        thousandsSeparators(num)
        {
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        }

    },

}
</script>

<style>

</style>

