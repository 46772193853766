<template>
   <!-- Dashboard Statistics -->
   <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
      <!-- Card -->
      <div v-for="campaign in campaigns"
         class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 shadow"
         >
         <div
            class="p-3 mr-4 text-red-800 rounded-full dark:text-green-100 dark:bg-green-500"
            >
          <i class="far fa-bullseye-pointer fa-2x"></i>
         </div>
         <div>
            <p
               class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
               >
               ({{ campaign.id }}) {{ campaign.name }}
            </p>
            <p
               class="text-lg font-semibold text-gray-700 dark:text-gray-200"
               >
            </p>
         </div>
      </div>
   </div>

</template>

<script>
export default {
data() {
    return {
        campaigns:null
    }
},
mounted() {
    this.getData();
},
methods: {
    getData(){

        axios.post('/get-campaigns').then( res => {

            this.campaigns = res.data.campaigns;

        });


    }
},

}
</script>

<style>

</style>
