<template>
  <div class="relative">
      <!-- XL Circles -->
      <div class="hidden xl:inline-flex	 rounded-full h-64 w-64 flex items-center justify-center bg-aberlour-blue absolute left-4 text-white text-3xl pl-10 font-bold">every child deserves the chance to thrive</div>
      <div class="hidden xl:inline-flex	 rounded-full h-32 w-32 flex items-center justify-center bg-aberlour-yellow opacity-75 absolute top-25 left-21 text-white"></div>
      <div class="hidden xl:inline-flex	 rounded-full h-64 w-64 flex items-center justify-center bg-aberlour-green  absolute bottom-0 right-0 text-white text-white text-3xl pl-10 font-bold">Your donation will give children a brighter future</div>
      <div class="hidden xl:inline-flex	 rounded-full h-32 w-32 flex items-center justify-center bg-aberlour-blue opacity-75 absolute bottom-25 right-21 text-white"></div>

    <!-- LG CIRCLES -->
     <div class="hidden lg:inline-flex  xl:hidden rounded-full h-64 w-64 flex items-center justify-center bg-aberlour-blue absolute left-109 text-white text-3xl pl-10 font-bold">every child deserves the chance to thrive</div>
      <div class="hidden lg:inline-flex	xl:hidden rounded-full h-32 w-32 flex items-center justify-center bg-aberlour-yellow opacity-75 absolute top-25 left-10 text-white"></div>
      <div class="hidden lg:inline-flex	xl:hidden rounded-full h-64 w-64 flex items-center justify-center bg-aberlour-green  absolute bottom-0 right-109 text-white text-white text-3xl pl-10 font-bold">Your donation will give children a brighter future</div>
      <div class="hidden lg:inline-flex	xl:hidden rounded-full h-32 w-32 flex items-center justify-center bg-aberlour-blue opacity-75 absolute bottom-25 right-13 text-white"></div>


  <!-- MD Circles -->
      <div class="hidden md:inline-flex lg:hidden rounded-full h-32 w-32 flex items-center justify-center bg-aberlour-blue absolute left-106 text-white text-xs pl-4 font-bold">every child deserves the chance to thrive</div>
      <div class="hidden md:inline-flex	lg:hidden rounded-full h-16 w-16 flex items-center justify-center bg-aberlour-yellow opacity-75 absolute top-14 left-5 text-white"></div>
      <div class="hidden md:inline-flex	lg:hidden rounded-full h-32 w-32 flex items-center justify-center bg-aberlour-green  absolute bottom-0 right-106 text-white text-white text-xs pl-4 font-bold">Your donation will give children a brighter future</div>
      <div class="hidden md:inline-flex	lg:hidden rounded-full h-16 w-16 flex items-center justify-center bg-aberlour-blue opacity-75 absolute bottom-14 right-5 text-white"></div>


    <div class="mt-1 sm:mx-auto sm:w-full sm:max-w-lg">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div
          v-if="errorMessage"
          class="font-semibold text-white py-2 bg-red-400 text-center mb-4 border border-red-900 border-2 rounded"
        >
          <i class="fal fa-exclamation-triangle mr-3"></i> {{ errorMessage }}
        </div>

        <transition-group name="fade" mode="out-in" class="grid grid-cols-4 gap-4" style="height:95px;">
          <donation-suggestion
          v-for="suggestion in donationSuggestions"
          :key="suggestion.amount"
          :suggestedAmount="suggestion.amount"
          :image="suggestion.image"
          :text="suggestion.text"
          :donationType="donationType"
          :selected="singleDonationAmount == suggestion.amount"/>
        </transition-group>


          <div class="col-span-4 mt-3" style="height: 250px;">
            <transition name="fade" mode="out-in">
                <donation-suggestion-banner
                :key="suggestionAmount"
                :image="suggestionImage"
                :amount="suggestionAmount"
                :donationType="donationType"
                :text="suggestionText">
                </donation-suggestion-banner>
            </transition>
          </div>

        <div class="sm:mx-auto sm:w-full">
          <div class="sm:grid sm:grid-cols-3 sm:gap-1 sm:items-start">
            <label
              for="amount"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Donation Amount
            </label>
            <div class="sm:mt-0 sm:col-span-3">
              <div class="max-w-lg flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                >
                  £
                </span>
                <input
                  v-model="singleDonationAmount"
                  type="number"
                  name="amount"
                  id="amount"
                  autocomplete="off"
                  @keyup="deselectSuggestions()"
                  class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full">
          <label
            for="card_holder_name"
            class="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input
              v-model="cardHolderName"
              name="card_holder_name"
              type="text"
              autocomplete="off"
              required
              class="block w-full border-gray-300 rounded-md placeholder-gray-400 sm:text-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            />
          </div>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full">
          <label
            for="card_holder_house_number"
            class="block text-sm font-medium text-gray-700"
          >
            House Number
          </label>
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input
              v-model="cardHolderHouseNumber"
              name="house_number"
              type="text"
              autocomplete="house_number"
              required
              class="block w-full border-gray-300 rounded-md placeholder-gray-400 sm:text-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            />
          </div>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full mb-3">
          <label
            for="card_holder_email"
            class="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input
              v-model="cardHolderEmail"
              name="card_holder_email"
              type="email"
              autocomplete="off"
              required
              class="block w-full border-gray-300 rounded-md placeholder-gray-400 sm:text-sm focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            />
          </div>
        </div>

        <div class="text-center w-full mb-3">
          <transition name="show">
            <p
              key="1"
              v-if="singleDonationAmount > 0 && giftAid"
              class="w-full font-semibold text-green-400 lg:text-lg text-sm"
            >
              <i class="far fa-thumbs-up mr-2"></i>Claiming Gift Aid
              <span class="underline font-extrabold">WILL</span> turn £{{ singleDonationAmount }}
              into £{{ singleDonationGiftAidAmount }}
            </p>
            <p
              key="2"
              v-else
              class="w-full font-semibold text-gray-700 lg:text-lg text-sm"
            >
              <i
                class="far fa-star-exclamation mr-2 text-purple-900 opacity-75"
              ></i
              >Claiming Gift Aid would turn £{{ singleDonationAmount ? singleDonationAmount : 0 }} into £{{ singleDonationGiftAidAmount }}
            </p>
          </transition>

          <div class="w-full font-semibold text-gray-400 text-xs">
            at no extra cost to you, the government adds £{{ singleDonationGiftAidAmount - singleDonationAmount  }}
          </div>
        </div>

        <div class="max-w-lg space-y-4 bg-gray-200 p-4 rounded">
          <div class="relative flex items-start">
            <div class="flex items-center h-5">
              <input
                v-model="giftAid"
                id="gift_aid"
                name="gift_aid"
                type="checkbox"
                class="focus:ring-purple-500 h-6 w-6 text-purple-900 border-gray-300 rounded cursor-pointer"
              />
            </div>
            <div class="ml-3">
              <label for="gift_aid" class="font-semibold text-gray-700"
                >Please Gift Aid my donation
              </label>
            </div>
          </div>
          <p class="text-gray-500 w-full">
            Yes, I am a UK taxpayer and I would like Aberlour to treat all
            donations I have made in the past four years and all donations I
            make in the future as Gift Aid donations until I notify you
            otherwise. I understand that if I pay less Income Tax and/or Capital
            Gains Tax than the amount of Gift Aid claimed on all my donations in
            the relevant tax year it is my responsibility to pay any difference.
            Please phone us on 0800 0856150 if your details or tax status
            change.
          </p>
        </div>

        <div class="mt-5 font-semibold text-gray-700">
          <i class="fad fa-lock-alt text-purple-900 mr-2 opacity-75"></i>Payment
          Methods:
        </div>
        <!-- card placeholder div -->
        <div
          id="card-element"
          class="p-4 mt-2 mb-2 border-t border-gray-200"
        ></div>

        <div>
          <span
            @click="pay()"
            :disabled="!complete"
            class="w-full flex justify-center py-3 px-5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-900 hover:bg-purple-800 cursor-pointer"
          >
            Donate Using Credit/Debit Card
          </span>
        </div>
        <payment-request-button
          class="py-2"
          stripe="pk_live_51HAaxJFm9WNCsyIrosxwgN74strdzIqR4IFtj6HroEmyhsV4xKMXd15VcWuB1AE58khdIdPekwMNfxe81W5GxL7B00qktumsi5"
          :options="paymentRequestOptions"
        />
        <paypal-button></paypal-button>

        <div id="paypal-button-container"></div>
      </div>
    </div>
  </div>
</template>

<script>

import PaymentRequestButton from './renderless/Payment-request-button.js';
import PaypalButton from './renderless/Paypal-button.js';

import smoothReflow from 'vue-smooth-reflow'

import DonationSuggestion from './Donation-suggestion.vue';
import DonationSuggestionBanner from './Donation-suggestion-banner.vue';




export default {
        mixins: [smoothReflow],

  data () {

    const stripeInstance = Stripe("pk_test_51ILK7jF3Ncdnrr9xf2Jtb54zTCaHLWcvkxg1UIc7R7X9N8an5CBx9RPzSxBJNUw8WRJc3gATDorgQGGMzZMAHVhS009jB9xezk")

   return {
      complete: false,
      stripe: stripeInstance,
      card:null,
      cardOptions: {
        style: {
                base: {
                iconColor: '#4C1D95',
                color: '#374151',
                fontWeight: '600',
                fontFamily: 'Nunito, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#374151',
                },
                '::placeholder': {
                    color: '#374151',
                },
                },
                invalid: {
                iconColor: '#EF4444',
                color: '#EF4444',
                },
        },
      },
      paymentRequestOptions:{
        country: 'GB',
        currency: 'gbp',
        total: {
            label: 'Donate to Aberlour',
            amount: 1,
        },
        requestPayerName: true,
        requestPayerEmail: true,

      },
      cardHolderName:null,
      cardHolderHouseNumber:null,
      cardHolderEmail:null,
      giftAid:null,
      singleDonationAmount:10,
      errorMessage:null,
      singleDonationSuggestions :[
          {amount: 10, image: '/img/brand/donation-suggestions/1.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {amount: 25, image: '/img/brand/donation-suggestions/2.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {amount: 50, image: '/img/brand/donation-suggestions/3.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {amount: 100, image: '/img/brand/donation-suggestions/4.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
      ],
       monthlyDonationSuggestions :[
          {amount: 3, image: '/img/brand/donation-suggestions/4.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {amount: 5, image: '/img/brand/donation-suggestions/3.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {amount: 10, image: '/img/brand/donation-suggestions/1.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {amount: 25, image: '/img/brand/donation-suggestions/2.jpg', text:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
      ],
      suggestionAmount: 10,
      suggestionImage: '/img/brand/donation-suggestions/1.jpg',
      suggestionText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      donationType: 'Single Donation',
      donationSuggestions: null
    }},

  computed: {
      singleDonationGiftAidAmount : function(){

          var value = this.singleDonationAmount * 1.25;

          return value.toFixed(2);

      }
  },

  components: {PaymentRequestButton, PaypalButton, DonationSuggestion, DonationSuggestionBanner},

  mounted() {
        this.card = this.stripe.elements().create('card', this.cardOptions );
        this.card.mount('#card-element')

            this.$root.$on('unselect-donation-suggestions', data =>{
            this.singleDonationAmount = data.suggestedAmount;
            this.suggestionAmount = data.suggestedAmount;
            this.suggestionImage = data.image;
            this.suggestionText = data.text;

        });

        this.donationSuggestions = this.singleDonationSuggestions;

         this.$root.$on('unselect-donation-type-selection', data =>{
            this.donationType = data.selected;

            //without this the transition on the donation suggestions is wonky
            this.donationSuggestions = [];

            setTimeout(() => {   if(this.donationType == "Single Donation"){
                this.donationSuggestions = this.singleDonationSuggestions;
            }else{
                this.donationSuggestions = this.monthlyDonationSuggestions;
            }}, 300);

        });


        this.$smoothReflow()

  },
  methods: {
    pay () {

        this.errorMessage = null;

        let c = this;

        this.stripe.createPaymentMethod({
            type: 'card',
            card: c.card,
            billing_details: {
                name: this.cardHolderName,
                email: this.cardHolderEmail,


            }
        })
        .then((res) => {
            if(res.error){
                 c.errorMessage = res.error.message;
            }else{
                c.sendPaymentMethod(res.paymentMethod);
            }
        })
        .catch((e) => {

        });
    },
    sendPaymentMethod(paymentMethod){

         axios.post('/donations/one-off', {
             'payment_method': paymentMethod,
             'amount': this.singleDonationAmount,
             'cardHolderName' : this.cardHolderName,
             'cardHolderHouseNumber' : this.cardHolderHouseNumber,
             'cardHolderEmail' : this.cardHolderEmail,
             'giftAid' : this.giftAid
             })
         .then( (data) => {


        window.location.href('https://www.aberlour.org.uk/thank-you/');



                    // this.$swal({
                    // title: 'Thank You',
                    // icon: 'success',
                    // text: 'Donation Processing - Please wait',
                    // timer: 2000,
                    // });

                    // this.redirect();


         })
         .catch( (error) => {
             if(error){
             this.errorMessage = error.response.data.message;
             }
         });
    },
    deselectSuggestions() {
        this.$root.$emit('unselect-donation-suggestions', {'suggestedAmount': this.singleDonationAmount});
        this.$root.$emit('manual-donation-amount-entered', {'amount': this.singleDonationAmount});

    },
    redirect(){


    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
