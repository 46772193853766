

export default {
    props:['total', 'donationInfo'],
    render() {

        var ev = this;

      paypal.Buttons({


        style: {
          shape: 'rect',
          color: 'white',
          layout: 'horizontal',
          label: 'paypal',
          tagline:false

        },

        createOrder: (data, actions) => {


          return actions.order.create({
            purchase_units: [{"amount":{"value":this.total}}]
          });
        },

        onApprove: (data, actions) => {
          return actions.order.capture().then((details)=> {
            //   console.log(details);
            //   console.log(data);
            // alert('Transaction completed by ' + details.payer.name.given_name + '!');

            axios.post('/donations-handler/paypal-donation', {
                'payment_method': details,
                'amount': this.donationInfo.donationAmount,
                'giftAid' : this.donationInfo.giftAid,
                'title': this.donationInfo.title,
                'first_name' : this.donationInfo.firstName,
                'last_name' : this.donationInfo.lastName,
                'email': this.donationInfo.email,
                'phone': this.donationInfo.phone,
                'address_house_number' : this.donationInfo.addressNumber,
                'address_line_1': this.donationInfo.addressLine1,
                'address_line_2': this.donationInfo.addressLine2,
                'address_town': this.donationInfo.addressTown,
                'address_region': this.donationInfo.addressRegion,
                'address_postcode': this.donationInfo.addressPostCode,
                'marketing_pref_email' : this.donationInfo.marketingPrefEmail,
                'marketing_pref_phone' : this.donationInfo.marketingPrefPhone,
                'marketing_pref_text' : this.donationInfo.marketingPrefText,
                'marketing_pref_post' : this.donationInfo.marketingPrefPost,
                'marketing_source_code': this.donationInfo.marketingSourceCode,
                'uk_taxpayer' : this.donationInfo.giftAid,
                'campaign' : this.donationInfo.campaign
                })
            .then( (res) => {

                var donationId = res.data.donation_id;

                // ga('ecommerce:addTransaction', {
                //   'id': donationId,                               // Transaction ID. Required.
                //   'affiliation': 'Aberlour Donation Platform',    // Affiliation or store name.
                //   'revenue': this.donationInfo.donationAmount,    // Grand Total.
                //   'shipping': '0',                                // Shipping.
                //   'tax': '0'                                      // Tax.
                //   });

                // ga('ecommerce:addItem', {
                //   'id': donationId,                               // Transaction ID. Required.
                //   'name': 'General Single Donation (Paypal)',             // Product name. Required.
                //   'sku': '1',                                     // SKU/code.
                //   'category': 'Donation',                         // Category or variation.
                //   'price': this.donationInfo.donationAmount,      // Unit price.
                //   'quantity': '1'                                 // Quantity.
                // });

                // ga('ecommerce:send');

                // ga('ecommerce:clear');

                      //  gtag('event', 'purchase', {
                      // 'allow_custom_scripts': true,
                      // 'value': this.donationAmount,
                      // 'transaction_id': this.donationInfo.donationAmount,
                      // 'send_to': 'DC-11763200/donat0/donat0+transactions'
                      // });

               window.location.href = '/thank-you/'+res.data.donation_id+'/'+res.data.donor_id+'/'+res.data.campaign;
            })
            .catch( (error) => {


                if(error.response){
                this.errorMessage = error.response.data.message;
                }else{
                     this.errorMessage = error;
                }
            });


          });
        },

        onError: function(err) {
          // console.log(err);
        }
      }).render('#paypal-button-container');

},

}
