<template>
  <modal
    name="card-authenticate-modal"
    width="500"
    height="700"
    :draggable="false"
    :click-to-close="false"
    transition="scale"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="text-gray-700">

    <div class=" w-full px-5 py-3">
        
        <div class="grid grid-cols-3 mb-5">
            <iframe width="400" height="600" :src="iframeSrc"></iframe>
        </div>

    </div>
    </div>
  </modal>
</template>

<script>

// import { EventBus } from "../Infrastructure/Event-bus.js"

export default {
  components: {
  },
  data() {
    return {
        iframeSrc: null,
    };
  },
  computed: {},
  methods: {
    beforeOpen(event) {

     this.iframeSrc  = event.params.iframeSrc;
    //  console.log(this.iframeSrc);
    },
    beforeClose(event) {
    },
    sendInfoRequest(){

        //todo

    }

  }
};
</script>

<style></style>
