import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        donationStages: [
            {number:1, title:'How much would you like to donate?', status:'active'},
            {number:2, title:'Your details', status:'todo'},
            {number:3, title:'Your address', status:'todo'},
            {number:4, title:'Keep in touch', status:'todo'},
            {number:5, title:'Payment Details', status:'todo'},
        ]
      },
      mutations: {
        goToNextStage(state, payload) {



           //set the next stage to active
           for (var i in state.donationStages) {
            if (state.donationStages[i].number == payload.number) {
                state.donationStages[i].status = 'active';
               break;
            }
          }


        //set the previous stage (i.e. the currently active) to complete
        for (var i in state.donationStages) {
            if (state.donationStages[i].status == 'active') {
                state.donationStages[i].status = 'complete';
                break;
            }
            }


        },
        goToClickedStage(state, payload) {

            //unset current active stage to todo
            for (var i in state.donationStages) {
                if (state.donationStages[i].status == 'active') {
                    state.donationStages[i].status = 'complete';
                    break;
                }
            }

            //set clicked as active
            for (var i in state.donationStages) {
                if (state.donationStages[i].number == payload.number) {
                    state.donationStages[i].status = 'active';
                    break;
                }
            }
        }

      },
      actions: {
        goToNextStage (context, payload) {
          context.commit('goToNextStage', payload)
        },
        goToClickedStage(context, payload){
          context.commit('goToClickedStage', payload)

        }
      },

      getters: {
        currentlyActiveStage (state) {

            let arr = state.donationStages;

            let obj = arr.find(o => o.status === 'active');

            return obj;
        },
        nextStage(state){
            let arr = state.donationStages;
            let obj = arr.find(o => o.status === 'active');
            let nextStagenumber = obj.number + 1;
            let nextStage = arr.find(o => o.number ===  nextStagenumber);

            return nextStage;
        },
        prevStage(state){
            let arr = state.donationStages;
            let obj = arr.find(o => o.status === 'active');
            let prevStagenumber = obj.number - 1;
            let prevStage = arr.find(o => o.number ===  prevStagenumber);

            return prevStage;
        },
        stages(state){

            return state.donationStages;

        }
      }


    })
